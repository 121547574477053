body * {
  font-family: "Roboto Flex", sans-serif;
}

body {
  background: radial-gradient(
    ellipse at center,
    rgba(255, 254, 234, 1) 0%,
    rgba(255, 254, 234, 1) 35%,
    #b7e8eb 100%
  );
  overflow: hidden;
}

* {
  border: 0px solid red;
}

h1 {
  color: #2bbbad;
}

hr {
  color: grey;
}

a {
  color: #2bbbad;
}

a:hover {
  color: orange;
}

a.nav-link {
  color: #2bbbad;
}

a.nav-link:hover {
  color: orange;
}

.centered {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  opacity: 0.5;
  border-radius: 5px;
  border: 1px solid rgb(0, 0, 0);
}

label {
  color: #2bbbad;
}
label:hover {
  color: orange;
}
