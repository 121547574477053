.logo {
  font-size: 40px;
  /* font-family: lobster, cursive; */
}

a.logo.navbar-brand {
  color: #2bbbad;
}

a.logo.navbar-brand:hover {
  color: #2bbbad;
}

a.logo.navbar-brand:visited {
  color: #2bbbad;
}

.userShield {
  font-size: 200px;
  color: #2bbbad;
}

button.btn.btn-primary {
  background-color: #2bbbad;
  outline: 0;
}

button.btn.btn-link {
  color: #2bbbad;
  outline: 0;
}

button.btn.btn-link:hover {
  color: orange;
  background-color: inherit;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  padding: 10px;
  border: 1px solid grey;
}

.progress-bar {
  height: 5px;
  background: orange;
  margin-top: 20px;
}

.error {
  color: red;
}

/* Full Screen */
@media only screen and (min-width: 600px) {
  .innercontent,
  .heading {
    margin-left: auto;
    margin-right: auto;
    min-width: 960px;
    width: 90%;
    max-width: 1200px;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .loginForm {
    margin-top: 20vh;
    margin-right: auto;
    margin-left: auto;
    max-width: 480px;
    justify-content: left;
    align-items: center;
  }

  .topnav {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/* Mobile Screen */
@media only screen and (max-width: 600px) {
  .topnav {
    padding-left: 15px;
    padding-right: 15px;
  }

  .innercontent,
  .heading {
    padding-left: 25px;
    padding-right: 25px;
  }

  .loginForm {
    margin-top: 5vh;
    margin-right: auto;
    margin-left: auto;
    max-width: 480px;
    justify-content: left;
    align-items: center;
  }

  .logo {
    font-size: 30px;
  }

  .logo-text {
    display: none;
  }

  .userShield {
    font-size: 150px;
  }
}
