.chat-main {
  height: 80vh;
  margin: 0 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: #0f3632;
  padding-top: 20px;
}

@media only screen and (min-width: 600px) {
  .chat-main {
    border-radius: 10px;
    border: 1px solid #2bbbad;
  }
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.473);
}

main::-webkit-scrollbar-thumb {
  background: #2bbbad7e;
}

.chat-form {
  position: fixed;
  bottom: 0px;
  font-size: 2rem;
  padding: 0 0 20px 0;
  background-color: #cacaca;
}

.chat-input {
  line-height: 1.5;
  height: 5vh;
  width: 80%;
  font-size: 1.5rem;
  background: #fff;
  color: #000;
  outline: none;
  border: 1px solid #2bbbad;
  padding: 0 10px;
}

.chat-button {
  font-size: 20px;
  margin-left: 5px;
  height: 5vh;
  width: 17%;
  padding: 0;
}

.chat-button:disabled {
  opacity: 0;
  cursor: not-allowed;
}

ul,
li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 2px 15px;
  border-radius: 11px;
  position: relative;
  color: white;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
}

.message {
  display: flex;
  align-items: center;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #2bbbad;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 12px;
}
