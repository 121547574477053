@import url("https://fonts.googleapis.com/css?family=Montserrat:900");

.text-animation {
  margin-top: 20vh;
  font-family: "Montserrat", sans-serif;
  font-size: 500px;
  letter-spacing: 1vw;
  color: white;
  background: url("https://i.pinimg.com/originals/9f/9c/c5/9f9cc5217bb04790e5fb852b665ca2ff.jpg");
  //background: url("https://thumbs.dreamstime.com/b/tropical-flower-pattern-seamless-flowers-blossom-flowers-nature-background-vector-illustration-61142748.jpg");
  background-size: auto 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 25s linear infinite;
  &:nth-child(2) {
    top: 50vh;
  }
  @keyframes shine {
    from {
      background-position: center 0;
    }
    to {
      background-position: center 200%;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 1400px) {
    font-size: 270px;
    letter-spacing: 1vw;
  }
  @media only screen and (max-width: 600px) {
    font-size: 120px;
    letter-spacing: 1vw;
    @keyframes shine {
      from {
        background-position: center 0;
      }
      to {
        background-position: center 200%;
      }
    }
  }
}
